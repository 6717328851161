import {MS_PER_DAY} from '@/constants';
import {MOBILE_WIDTH_RESOLUTION_PHONE} from '@/constants';
import {switchDateFormat} from '@/helpers/format-date-service';
import {googleIconBase64} from '@/constants';
import { localToTimestamp } from '@/helpers/format-date-service';

const periodsConfig = [
    {value: 10, text: '10 days'},
    {value: 30, text: '30 days'},
    {value: 60, text: '60 days'},
    {value: 90, text: '90 days'},
    {value: 180, text: '180 days'},
    {value: 365, text: '365 days'},
    {value: 730, text: '730 days'},
    {value: 666666, text: 'All time'},
];



const config = (notes, projects, leaveNote, competitorData = {}, incidents) =>
{
    let chart
    return ({
        isDataIncluded: true,
        isExportEnabled: true,
        legend: {
            align: 'center',
            y: 65,
            symbolPadding: 0,
            symbolWidth: 0.1,
            symbolHeight: 0.1,
            symbolRadius: 0,
            useHTML: true,
            symbolWidth: 0,
            labelFormatter: function() {
                return `<div style="display: inline-flex; align-items: center; gap: 3px; font-family: Inter, Helvetica, Arial, sans-serif; font-weight: 500; font-size: 10px;"><span style="display: inline-block; background: ${this.color}; width: 7px; height: 7px; border-radius: 50%;"></span>${this.name}</div>`
            },
            itemStyle: {
                color: '#000000',
                fontSize: '10px'
            }
        },
        series: [],
        title: {text: ''},
        credits: {
            href: 'https://app.keyword.com/',
            text: '',
        },
        chart: {
            customBackground: false,
            style: {
                overflow: 'visible',
            },
            events: {
                load() {
                    chart = this;
                    const tooltipClass = 'highcharts-point';

                    chart.container.addEventListener('mouseover', function(event) {
                        if (event.target && event.target.classList.contains(tooltipClass) && event.target.tagName==='image') {
                          chart.pointer.reset = function() {}; // Disable the tooltip reset
                          chart.tooltip.hide = function() {}; // Disable the tooltip hide
                        }
                    });
                    chart.container.addEventListener('mouseleave', function(event) {
                        delete chart.tooltip.hide;
                        delete chart.pointer.reset
                    });
                },
                render(){
                      // Clear previous custom lines if they exist
                    if (chart.customLines) {
                        chart.customLines.forEach(line => line.destroy());
                    }
        
                    // Initialize the customLines array
                    chart.customLines = [];
        
                    // Left border
                    chart.customLines.push(chart.renderer.path([
                        'M', chart.plotLeft, chart.plotTop,
                        'L', chart.plotLeft, chart.plotTop + chart.plotHeight
                    ])
                    .attr({
                        'stroke-width': 1,
                        stroke: '#C1C4CD'
                    })
                    .add());
                }
            },
            zoomType: 'x',
            resetZoomButton: {
                theme: {display: 'none'},
            },
        },
        exporting: {
            enabled: true,
            fallbackToExportServer: true,
            buttons: {
                contextButton: {enabled: false},
            },
            chartOptions: {
                plotOptions: {
                    series: {
                        dataLabels: {enabled: false},
                    }
                },
            },
        },
        colors: [
            '#176BFA',
            '#FA8117',
            '#AE17FA',
            '#910000',
            '#1aadce',
            '#492970',
            '#f28f43',
            '#77a1e5',
            '#c42525',
            '#a6c96a',
        ],
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function () {
                    return switchDateFormat(new Date(this.value), 'chart-date');
                },
            },

                // crosshair: {
                //   color: '#000000',
                //   dashStyle: 'shortdot'
                // }

        //     plotLines: [{ // mark the weekend
        //         color: '#cecece',
        //         width: 2,
        //         value: Date.UTC(2024, 7, 5),
        //         dashStyle: 'ShortDot'
        //     },
        //     {
        //         color: '#cecece',
        //         width: 2,
        //         value: Date.UTC(2024, 7, 1),
        //         dashStyle: 'ShortDot'
        //     }
        // ],
        },
        yAxis: [{
            allowDecimals: false,
            reversed: true,
            title: {text: ''},
            
        }],
        plotOptions: {
            series: {
                lineWidth: 1,
                stickyTracking: false,
                pointInterval: MS_PER_DAY,
                pointStart: Date.UTC(2010, 0, 1),
                marker: {
                    enabled: true,
                    radius: 2.5,
                    symbol: 'circle',
                    fillColor: '#fff', // Make the fill transparent
                    lineWidth: 1.5, // Width of the outline
                    lineColor: null
                },
                point: {
                    events: {
                        click: function () {
                            if (this.description || window.screen.width <= MOBILE_WIDTH_RESOLUTION_PHONE) {
                                return;
                            }
    
                            const {series: {index, chart}, x} = this;
    
                            const isCompetitorData = chart.options.series[index].name === competitorData.competitorDomain;
                            leaveNote({
                                date: switchDateFormat(new Date(x), 'Y-m-d'),
                                projectId: projects[0],
                                ...isCompetitorData && { competitorDomain: competitorData.competitorDomain, keywordId: competitorData.keywordId}
                            });
                        },
                    }
                },
                events: {
                    mouseOver: function () {
                        if (this.type === 'scatter') {
                            this.chart.xAxis[0].update({
                                crosshair: {
                                    color: '#000000', // Show crosshair for scatter series
                                    dashStyle: 'shortdot',
                                    width: 1
                                }
                            });
                        } else {
                            this.chart.xAxis[0].update({
                                crosshair: {
                                    color: 'transparent', // Hide crosshair for other series
                                    width: 0
                                }
                            });
                        }
                    }
                }
            },
            line: {
                stickyTracking: false
            },
        },
        noData: {
            style: {
                color: '#303030',
                fontSize: '15px',
                fontWeight: 'bold',
            },
        },
        tooltip: {
            snap: 0,
            stickOnContact: true,
            shared: true,
            backgroundColor: '#fff',
            style: {
                opacity: 0.8,
                pointerEvents: 'auto'
            },
            borderColor: '#AEBBC8',
            shadow: {
                color: 'rgba(0, 0, 0, 0.25)',
                offsetX: 0,
                offsetY: 1,
                width: 4,
            },
            useHTML: true,
            formatter: function () {
                if(this.point?.hasImage && this.point?.description){
                    return `<div class="keyword-chart-incident-tooltip">
                    ${this.point.description}
                    </div`
                }


                // date of point formatted for compare
                const date = switchDateFormat(new Date(this.x), 'new-note');
    
                let isThereManual = false;
                // notes filtering by date
                const notesByDate = projects.reduce((acc, cur) => {
                    notes[cur] && acc.push(...notes[cur].filter(n => switchDateFormat(new Date(n.attributes.created_at), 'new-note') === date));
                    return acc;
                }, []);
    
                return `<div class="keyword-chart-tooltip">
                    ${notesByDate.length ? `
                        <div class="keyword-chart-tooltip__container">
                            <h6 class="keyword-chart-tooltip__title">Notes</h6>
                            <div class="keyword-chart-tooltip__content">
                                ${notesByDate.reduce((acc, cur) => {
                                    acc += `<p>${cur.attributes.note}</p>`;
                                    return acc;
                                }, '')}
                            </div>
                        </div>` : ''
                    }
                    <div class="keyword-chart-tooltip__container">
                        <h6 class="keyword-chart-tooltip__title">${switchDateFormat(new Date(this.x), 'l F jS, Y')}</h6>
                        <div class="keyword-chart-tooltip__content">
                            ${this.points.reduce((acc, cur) => {
                                let manual = cur.series.options.manualStart;
    
                                if (manual) {
                                    manual = switchDateFormat(new Date(cur.series.options.first_rank), 'new-note') === date;
                                    isThereManual = isThereManual || manual;
                                }
    
                                acc += `<div class="chart-tooltip__line">
                                    <i style="background-color: ${cur.color}; height: 4px; width: 4px;" class="chart-tooltip__marker"></i>
                                    ${cur.series.name}:<b> ${cur.y}</b>${manual ? '<i style="color: red">*</i>' : ''}
                                </div>`;
                                return acc;
                            }, '')}
                            ${isThereManual ? '<div style="margin-top: 10px;">' +
                                '<i style="color: red; vertical-align: middle;">* </i>' +
                                '<span style="color: #838383">Start rank manually updated. Verified Spyglass rank may not match.</span>' +
                                '</div>' : ''
                            }
                        </div>
                    </div>
                </div>`;
            },
        },
    });
}


const addSeriesToOptions = ({getSeries, chartOptions, notes, projectId = null, keywordId = null, incidents = []}) => {
    // make sure there is at least one valid rank in the series
    if (getSeries.some(el => el.data.some(ol => ol[1] > 0))) {
        chartOptions.series = _.cloneDeep(getSeries);

        const scatterSeries = {
            type: 'scatter',
            name: 'google',
            data: [], // Initialize an empty array to hold scatter data points
            color: '#000000',
            showInLegend: false,
            lineWidth: 0,
        };
        //add google incidents
        const seriesData = getSeries?.[0].data;
        if(seriesData?.length){
            const start = seriesData[0][0]
            const end = seriesData[seriesData.length - 1][0]

            scatterSeries.data = incidents.reduce((acc,item) => {
                const incidentDate = localToTimestamp(item.incident_begin_date)

                if(incidentDate >= start && incidentDate <= end){
                    acc.push({
                        x: incidentDate, 
                        y: 0,
                        marker: {
                            symbol: `url(${googleIconBase64})`,
                        },
                        description: `<div class="title"><span>${item.external_description}</span></div>
                        <div class="date"><span>${switchDateFormat(new Date(item.incident_begin_date), 'F jS, Y hh:mm')}</span></div>
                        <div class="desc"><span>${item.update_text}</span></div>
                        <div class="read-more"><a href="${item.read_more_url}" target="_blank"><span class="kw kw-info"></span>Read more</a></div>`
                    })

                    return acc;
                }
            
            }, []);
        }
        // if date has notes - we should change marker icon
        chartOptions.series.forEach(s => {
            const projectName = s.project ? s.project : projectId;

            if (!notes[projectName] || !notes[projectName].length) {
                return;
            }

            s.data = s.data.map(
                d => notes[projectName].some(n => {
                    if (
                        (s.project && !n.attributes.competitor_domain)
                        || (
                            !s.project && n.attributes.competitor_domain
                            && n.attributes.competitor_domain === s.name
                            && +n.attributes.keyword_id === +keywordId
                        )
                    ) {
                        return switchDateFormat(new Date(n.attributes.created_at), 'new-note') === switchDateFormat(new Date(d[0]), 'new-note');
                    }
                })
                    ? {x: d[0], y: d[1], marker: {symbol: 'url(https://keyword.com/serp/images/notes.png)'}}
                    : d
                );
        });

        // Add the scatter series to the chart options
        chartOptions.series.push(scatterSeries);
    } else {
        chartOptions.series = [];
        // If there is no data - don't use background image.
        chartOptions.chart.plotBackgroundImage = null;
    }

    if (!chartOptions.series.length) {
        chartOptions.series = [
            { type: 'line', name: 'Google', data: [] },
        ];

        chartOptions.isExportEnabled = false;
        return chartOptions;
    }

    chartOptions.isExportEnabled = true;
    return chartOptions;
};

const getFileName = (keywords) => {
    return keywords.length === 1
        ? keywords[0].kw
        : keywords.reduce((acc, cur) => acc += `${cur.kw}, `, '').substr(0, 235);
};

export {config, addSeriesToOptions, getFileName, periodsConfig};
