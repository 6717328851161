import apiFactory from '@/api';
import i18n from '@/i18n';
import toastr from '@/helpers/init-toastr';
import router from '@/routes';
const metricsApi = apiFactory.get('metrics');
const incidentsApi = apiFactory.get('incidents');

import {
    SET_COMPETITORS_DATA,
    SET_SERIES,
    SET_SHOWN_CHART,
    SET_COMPETITORS_SUGGESTIONS,
    SET_SERP_HISTORY_RESULTS,
    SET_PDF_CHARTS_DATA,
    SET_PDF_CHARTS_DATA_IS_FETCHING,
    SET_COMMON_KEYWORDS_IS_SHOW,
    SET_GOOGLE_INCIDENTS
} from '@/store/mutations';

import { convertDate } from '../../helpers/historicalChartsHelpers';

export const chartsPersistedKeys = [];

const charts = {
    state: {
        commonKeywordsChartIsShow: false,
        competitorsData: [],
        serpHistoryResults: [],
        keywordCompetitorSuggestions: [],
        series: [],
        shownChart: { keyword: null, project: null },
        pdfChartsData: [],
        pdfChartsDataIsFetching: false,
        googleIncidents: [],
    },
    mutations: {
        [SET_COMPETITORS_DATA]: (state, payload) => (state.competitorsData = payload),
        [SET_COMPETITORS_SUGGESTIONS]: (state, payload) => state.keywordCompetitorSuggestions = payload,
        [SET_SERIES]: (state, series) => (state.series = series),
        [SET_SHOWN_CHART]: (state, chart) => (state.shownChart = chart),
        [SET_SERP_HISTORY_RESULTS]: (state, payload) => (state.serpHistoryResults = payload),
        [SET_PDF_CHARTS_DATA]: (state, payload) => state.pdfChartsData = payload,
        [SET_PDF_CHARTS_DATA_IS_FETCHING]: (state, payload) => state.pdfChartsDataIsFetching = payload,
        [SET_COMMON_KEYWORDS_IS_SHOW]: (state, payload) => state.commonKeywordsChartIsShow = payload,
        [SET_GOOGLE_INCIDENTS]: (state, payload) => state.googleIncidents = payload,
    },
    getters: {
        getCommonKeywordsChartIsShow: state => state.commonKeywordsChartIsShow,
        getSeries: state => state.series,
        getShownChart: state => state.shownChart,
        getCompetitorsData: state => state.competitorsData,
        getKeywordCompetitorSuggestions: state => state.keywordCompetitorSuggestions,
        getSerpHistoryResults: state => state.serpHistoryResults,
        getPdfChartsData: state => state.pdfChartsData,
        getPdfChartsDataIsFetching: state => state.pdfChartsDataIsFetching,
        getGoogleIncidents: state => state.googleIncidents,
    },
    actions: {
        async fetchChartData({ commit, getters }, keywords) {
            let data = [];
            const queryData = { kwid: keywords.map(el => `${el.id}|${el.auth}`) };
            const queryParams = {
                action: 'bulkChart',
                limit: getters.getKeywordSetting('chartPeriod'),
            };

            try {
                let res;

                if (getters.getViewkeyView) {
                    const { name: routeName, params: routeParams } = router.history.current;

                    if (routeName === 'shareViewkey' || routeName === 'shareKeywordsTable') {
                        const viewkey = getters.getProjectProperty('shortkey');
                        const auth = getters.getProjectProperty('auth');
                        res = await metricsApi.fetchViewKeyChartData(viewkey, queryData, { ...queryParams, auth });
                    } else {
                        res = await metricsApi.fetchViewKeyChartDataWithHash(routeParams.hash, queryData, queryParams);
                    }
                } else {
                    res = await metricsApi.fetchChartData(queryData, queryParams);
                }

                if (res.data && Array.isArray(res.data.data)) {
                    data = res.data.data.map(el => {
                        let id = el.data[3].split('|');
                        let keyword = getters.getKeywordById(id[0]);

                        return {
                            id: id[1],
                            kwId: id[0],
                            first_rank: convertDate(el.data[0][0].data[0][0]),
                            manualStart: keyword?.notes?.manual_start,
                            name: `${el.data[0][0].name} - ${el.data[2].split('|')[1]}`,
                            project: el.data[2].split('|')[2],
                            rankingUrls: el.data[4] && el.data[4]['rankingurls'] ? el.data[4]['rankingurls'] : [],
                            data: el.data[0][0].data.map(([date, value]) => [convertDate(date), value]),
                        };
                    });
                }

                commit(SET_SERIES, [...data, ...getters.getCompetitorsData]);
            } catch (error) {
                commit(SET_SERIES, []);
            }
        },
        async fetchCompetitorsData({ getters, dispatch }, { domain, keywords }) {
            let limit = getters.getKeywordSetting('chartPeriod');
            const projectName = keywords[0].category;
            let data = [];

            if (getters.getCompetitorsData.find(el => el.name === domain)) {
                return;
            }

            const queryString = { params: { competitor_domain: domain, limit } };

            try {
                const res = await metricsApi.fetchKeywordsCompetitorsMetrics(
                    projectName,
                    keywords[0].id,
                    queryString,
                );

                if (!res.data.data.length) {
                    toastr.e(i18n.t('no-competitor-ranking'));
                    throw new Error(i18n.t('no-competitor-ranking'));
                }

                data = {
                    data: res.data.data.map(elem => {
                        return [Date.parse(elem.date), elem.rank];
                    }),
                    id: Math.floor(Math.random() * 100),
                    name: `${domain}`,
                };
                dispatch('setCompetitorsData', [...getters.getCompetitorsData, data]);
            } catch (error) {
                console.error(error);
            }

            return true;
        },
        async fetchKeywordCompetitorSuggestions({ commit, getters }, keywords) {
            const projectName = keywords[0].category;

            try {
                let res;
                const { name: routeName, params: routeParams } = router.history.current;

                const queryParams = {
                    date: getters.getKeywordFilters.keywordsDate || null,
                };

                if (
                    routeName === 'shareViewkeyWithHash' ||
                    routeName === 'shareKeywordsTableWithHash'
                ) {
                    res = await metricsApi.fetchKeywordCompetitorSuggestionsWithHash(
                        routeParams.hash,
                        keywords[0].id,
                        queryParams,
                    );
                } else {
                    res = await metricsApi.fetchKeywordCompetitorSuggestions(
                        projectName,
                        keywords[0].id,
                        queryParams,
                    );
                }

                commit(SET_COMPETITORS_SUGGESTIONS, res.data.data);
            } catch (error) {
                console.error(error);
            }
        },
        async fetchKeywordSerpHistory({ commit, getters }, { keywords, dateRange }) {
            const projectName = keywords[0].category;
            let queryData = {
                params: {
                    dateRange
                },
            };

            try {
                let res;
                const { name: routeName, params: routeParams } = router.history.current;

                if (getters.getViewkeyView) {
                    queryData.params.auth = getters.getCurrentProject.auth;
                    queryData.params.password = getters.getCurrentProject.password;

                    if (
                        routeName === 'shareViewkeyWithHash' ||
                        routeName === 'shareKeywordsTableWithHash'
                    ) {
                        res = await metricsApi.fetchKeywordSerpHistorySharedWithHash(
                            routeParams.hash,
                            keywords[0].id,
                            queryData
                            );
                        } else {
                        res = await await metricsApi.fetchKeywordSerpHistoryShared(
                            getters.getCurrentProject.shortkey,
                            keywords[0].id,
                            queryData
                            );
                        }
                    } else {
                    res = await metricsApi.fetchKeywordSerpHistory(
                        projectName,
                        keywords[0].id,
                        queryData,
                    );
                }

                if (res.data.data.length) {
                    commit(SET_SERP_HISTORY_RESULTS, res.data.data);
                } else {
                    commit(SET_SERP_HISTORY_RESULTS, []);
                }
            } catch (error) {
                console.error(error);
            }
        },
        async fetchAnalyticsChartData({ getters }, currentCategory) {
            if (!getters.getPermissionData('google_integrations')) {
                throw new Error(i18n.t('upgrade-for-google', { service: i18n.t('google-analytics') }));
            }

            let res;
            const { getViewkeyView } = getters;
            const { id, password } = currentCategory;

            if (!id) {
                throw new Error(i18n.t('project-not-found'));
            }

            const { name: routeName, params: routeParams } = router.history.current;

            if (getViewkeyView) {
                if (
                    routeName === 'shareViewkeyWithHash' ||
                    routeName === 'shareKeywordsTableWithHash'
                ) {
                    // view-key api with hash
                    res = await metricsApi.fetchAnalyticsChartDataForShareViewKeysWithHash(routeParams.hash);
                } else {
                    res = await metricsApi.fetchAnalyticsChartDataForShareViewKeys(
                        currentCategory.shortkey,
                        {
                            params: {
                                auth: currentCategory.auth,
                                password: password,
                            },
                        },
                    );
                }
            } else {
                res = await metricsApi.fetchAnalyticsChartData(id);
            }

            return res?.data?.data ? res.data.data[0] : {};
        },
        setShownChart({ commit }, payload) {
            commit(SET_SHOWN_CHART, payload)
        },
        async fetchPdfCharts({ commit, getters }) {
            commit(SET_PDF_CHARTS_DATA_IS_FETCHING, true);

            const queryData = { kwid: getters.getOriginalItems.map(el => `${el.id}|${el.auth}`) };
            const viewkey = getters.getProjectProperty('shortkey');
            const auth = getters.getProjectProperty('auth');
            const queryParams = {
                action: 'bulkChart',
                limit: getters.getKeywordSetting('chartPeriod'),
                auth,
            };

            try {
                const data = [];
                const res = await metricsApi.fetchViewKeyChartData(viewkey, queryData, queryParams);

                if (res.data && Array.isArray(res.data.data)) {
                    res.data.data.forEach(el => {
                        data.push([{
                            id: el.data[3].split('|')[1],
                            kwId: el.data[3].split('|')[0],
                            name: el.data[0][0].name,
                            project: el.data[2].split('|')[2],
                            rankingUrls: el.data[4] && el.data[4]['rankingurls'] ? el.data[4]['rankingurls'] : [],
                            data: el.data[0][0].data.map(([date, value]) => [convertDate(date), value]),
                        }]);
                    });
                }

                commit(SET_PDF_CHARTS_DATA, data);
            } catch (e) {
                commit(SET_PDF_CHARTS_DATA, []);
            } finally {
                commit(SET_PDF_CHARTS_DATA_IS_FETCHING, false);
            }
        },
        toggleCommonKeywordsChartIsShow({ commit, getters }) {
            const storeData = getters.getCommonKeywordsChartIsShow;
            commit(SET_COMMON_KEYWORDS_IS_SHOW, !storeData);
        },
        setCompetitorsData({ commit }, payload) {
            commit(SET_COMPETITORS_DATA, payload);
        },
        async fetchGoogleIncidents({ commit, getters }, keywords) {
            try {
                const res = await incidentsApi.fetch();

                commit(SET_GOOGLE_INCIDENTS, res.data.data);
            } catch (error) {
                console.error(error);
            }
        },
    },
};

export default charts;
